import { Center, Flex, Icon, Spinner, Tag, Text, VStack } from "@chakra-ui/react"
import { differenceInSeconds, format } from "date-fns"
import React from "react"
import { XCircle } from "react-feather"
import { useUserCurrentLocationRecordQuery } from "../../graphql"
import { getDurationInWords } from "../../utils"
import { Card, Stat } from "../common"

export type UserCurrentLocationProps = {
	userId: string
}

export const UserCurrentLocation: React.FC<UserCurrentLocationProps> = ({ userId }) => {
	const [{ data, fetching, error }] = useUserCurrentLocationRecordQuery({ variables: { userId } })

	return (
		<Card title="Current Location">
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading current location</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.userCurrentLocationRecord ? (
				<VStack w="full" align="flex-start" p="2">
					{data.userCurrentLocationRecord.isRestricted && (
						<Tag colorScheme="error" variant="solid">
							Restricted <Icon as={XCircle} ml="2" />
						</Tag>
					)}
					<Flex w="full" justify="space-between" align="center">
						<Stat label="Zone" value={data.userCurrentLocationRecord.zone.label.name} />
						<Stat label="Since" value={format(new Date(data.userCurrentLocationRecord.startAt), "MMM dd, yyyy HH:mm")} />
						<Stat label="Duration" value={getDurationInWords(differenceInSeconds(new Date(data.userCurrentLocationRecord.endAt), new Date(data.userCurrentLocationRecord.startAt)))} />
					</Flex>
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="md" color="error.500">
						Couldn&apos;t find current location
					</Text>
				</Center>
			)}
		</Card>
	)
}
