import { Divider, List } from "@chakra-ui/react"
import React from "react"
import { ExtendedUserLocationRecordFragment } from "../../graphql"
import { ZonedUserLocationListItem } from "./ZonedUserLocationListItem"

export type ZonedUserLocationsListProps = {
	userLocationRecords: ExtendedUserLocationRecordFragment[]
}

export const ZonedUserLocationsList: React.FC<ZonedUserLocationsListProps> = ({ userLocationRecords }) => {
	return (
		<List spacing={2}>
			{userLocationRecords.map((userLocationRecord) => (
				<React.Fragment key={userLocationRecord._id}>
					<ZonedUserLocationListItem userLocationRecord={userLocationRecord} />
					<Divider />
				</React.Fragment>
			))}
		</List>
	)
}
