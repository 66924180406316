import { List } from "@chakra-ui/react"
import React from "react"
import { AlertTypes, ExtendedAlertFragment } from "../../graphql"
import { Card } from "../common"
import { ReaderListItem } from "../readers/ReaderListItem"
import { RestrictedEntryListItem } from "../restrictedEntries/RestrictedEntryListItem"
import { TagListItem } from "../tags/TagListItem"
import { UserRouteListItem } from "../userRouteRecords/UserRouteListItem"
import { UserRoleListItem } from "../users"
import { UserListItem } from "../users/UserListItem"
import { ZoneListItem } from "../zones/ZoneListItem"

export type AlertSourceProps = {
	alert: ExtendedAlertFragment
}

export const AlertSource: React.FC<AlertSourceProps> = ({ alert }) => {
	return (
		<Card title="Source">
			<List>
				{alert.type === AlertTypes.InactiveReader && alert.reader ? (
					<ReaderListItem reader={alert.reader} />
				) : alert.type === AlertTypes.RestrictedEntry && alert.userLocationRecord ? (
					<RestrictedEntryListItem userLocationRecord={alert.userLocationRecord} />
				) : alert.type === AlertTypes.TagBatteryLow && alert.tag ? (
					<TagListItem tag={alert.tag} />
				) : alert.type === AlertTypes.UserRoleExpiry && alert.userRole ? (
					<UserRoleListItem userRole={alert.userRole} includeUser />
				) : (alert.type === AlertTypes.IdleZone || alert.type === AlertTypes.Crowd) && alert.zone ? (
					<ZoneListItem zone={alert.zone} />
				) : alert.type === AlertTypes.IncorrectRoute && alert.userRouteRecord ? (
					<UserRouteListItem userRouteRecord={alert.userRouteRecord} />
				) : alert.type === AlertTypes.IncorrectRouteTime && alert.userRouteRecord ? (
					<UserRouteListItem userRouteRecord={alert.userRouteRecord} />
				) : alert.type === AlertTypes.SOS && alert.userId && alert.user ? (
					<UserListItem user={alert.user} />
				) : alert.type === AlertTypes.AwakeStatusLogExpiry && alert.awakeStatusLog?.user ? (
					<UserListItem user={alert.awakeStatusLog.user} />
				) : (
					<></>
				)}
			</List>
		</Card>
	)
}
